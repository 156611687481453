import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";
import CourseItem from "./CourseItem";
// import { fetchPost } from "../pages/Services"

const CouseData = () => {
  const [response, setResponse] = useState([]);
  const [todos, setTodos] = useState([]);

  const fetchPost = async () => {
    const userID = JSON.parse(localStorage.getItem('authUser')).uid;
    await getDocs(query(collection(db, "kursai", userID, "ikelti")))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setTodos(newData);                
            setResponse(newData);                
            // console.log(newData);
        })
   
}
  //call data on page load
  useEffect(() => {
    fetchPost(setResponse);
  }, []);

//   console.log(response);
  return (
    <div className='services'>

      {response.map((items, index) => (
        <div>

            <div>{items.pavadinimas}</div>
            <div>{items.aprasymas}</div>
            <div>{items.kategorija}</div>
        </div>
      ))}
    </div>
  );
};

export default CouseData;
