// import node module libraries
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// import sub components
import CourseCard from './CourseCard';

// import data files
import { AllCoursesData, Context, fetchAllFirestoreData } from 'data/slider/AllCoursesData';

const CourseSlider = ({ recommended, popular, trending, category }) => {

	

	const settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2
				}
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}
	const AllMemo = useMemo(() => {
		fetchAllFirestoreData().then((res) => {
			setRecords(res)
		});
		// console.log("===============================================suveikia useMemo");
	}, []);

	// console.log(AllMemo);
	const [Records, setRecords] = useState([]);
	// const [read, setRead] = useState(false);

	// console.log("pradiniai duomenys", "records ", Records.length );

	// useEffect(() => {
	// 	if (Records.length === 0) {
	// 		fetchAllFirestoreData().then((res) => {
	// 			console.log("response ", res);
	// 			setRecords(res);
	// 			setRead(true);
	// 			console.log("priskirti records");
	// 			console.log("paleidus funkcija", "records ", Records.length, "alldata " );
	// 		});
	// 	}

	// 	console.log("pirmas effect else", "records ", Records.length, "alldata " );
	
	// }, []);

	// useEffect(() => {
	// 	if (Records.length > 0 && read === true) {
	// 		setRead(false);
	// 		console.log("pasikeitus", Records);
	// 		console.log("paleidus antra effect if", "records ", Records.length, "alldata " );
	// 	}
	// 	else {
	// 		console.log("antras useefect else");
	// 		console.log("antras effect else", "records ", Records.length, "alldata " );
	// 	}
	// }, [read]);



	return (
		<Fragment>
			<Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">
				{Records.filter(function (dataSource) {
					if (recommended === true) {
						dataSource = dataSource.recommended;
					}
					if (popular === true) {
						dataSource = dataSource.popular;
					}
					if (trending === true) {
						dataSource = dataSource.trending;
					}
					if (category != null) {
						dataSource = dataSource.category === category;
					}
					return dataSource;
				}).map((item, index) => (
					<div className="item px-md-1" key={item.id}>
						<CourseCard key={index} item={item} extraclass="mx-2" />
					</div>
				))}
			</Slider>
		</Fragment>
	);
};

// Specifies the default values for props
CourseSlider.defaultProps = {
	recommended: false,
	popular: false,
	trending: false,
	category: null
};

// Typechecking With PropTypes
CourseSlider.propTypes = {
	recommended: PropTypes.bool,
	popular: PropTypes.bool,
	trending: PropTypes.bool,
	category: PropTypes.string
};

export default CourseSlider;
