// import node module libraries
import { auth, db, storage } from '../../../../../../../src/firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import { useEffect, useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { v4 } from 'uuid';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import axios from 'axios';
import { Stream } from '@cloudflare/stream-react';
// import {tus} from 'tus-js-client';
import React, { useCallback, useState, useEffect } from 'react';
import * as tus from 'tus-js-client';


const CoursesMedia = (props) => {
	const [progress, setProgress] = useState(0);
	const [vidUpload, setVidUpload] = useState(undefined);
	const [uploadFile, setUploadFile] = useState(undefined);

	// useEffect(() => {
	// 	if (vidUpload?.name) {

	// 	}
	// }, [vidUpload?.name])

	//========================================tus=====================================================

	const tusUpload = async () => {

		const ACCOUNT_ID = "3cb967416930271b3c8fec94a0228525";
		const endpoint = `https://api.cloudflare.com/client/v4/accounts/${ACCOUNT_ID}/stream`;
		const token = 'O1Qe2QWKwtLo1GIw0messqZKUW2u9oEgJlKajDtS';
		const file = uploadFile;

		const options = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			chunkSize: 25 * 1024 * 1024, // 25MB chunk size
		};
		const upload = new tus.Upload(file, {
			endpoint,
			...options,
			onError: (error) => {
				console.error('Upload error:', error);
			},
			onProgress: (bytesUploaded, bytesTotal) => {
				const progress = (bytesUploaded / bytesTotal) * 100;
				console.log(`Upload progress: ${progress.toFixed(2)}%`);
			},
			onSuccess: () => {
				console.log('Upload complete');
			},
		});
		upload.start();
	}

	//========================================tus=====================================================

	return (
		<Form>
			{/* Card */}
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Courses Media</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Course cover image */}
					<Form.Label>Course cover image</Form.Label>
					<Form.Group className="mb-1 input-group">
						<Form.Control
							id="inputfavicon"
							type="file"
							className="form-control"
						/>
						<Form.Label
							htmlFor="inputfavicon"
							className="input-group-text mb-0"
						>
							Upload
						</Form.Label>
						<Form.Text className="text-muted">
							Upload your course image here. It must meet our course image
							quality standards to be accepted. Important guidelines: 750x440
							pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
						</Form.Text>
					</Form.Group>
					{/* Course video file */}
					<Form.Label>Course video file</Form.Label>
					<Form.Group className="mb-1 input-group">

					</Form.Group>
					<Form.Control
						id="inputfavicons"
						type="file"
						className="form-control"
						accept="video/*"
						onChange={(e) => {
							setUploadFile(e.target.files[0]);
						}}
					/>
					<Form.Text className="text-muted">
						Upload your course video here. It must meet our course image quality standards to be accepted. Important guidelines: max resulution 1920x1080
						pixels; Maximum video file size is 2GB and with no time limit.
					</Form.Text>
					<Button onClick={tusUpload} type='button' variant="outline-secondary" id="button-addon2">Uplaod i stream</Button>
					<div>
					</div>

					{/* Video URL  */}
					<Form.Group className="mb-3 mt-3">
						<Form.Label>Course promo video</Form.Label>
						<Form.Control type="text" placeholder="Video URL" id="VideoURL" onChange={(e) => setField('cPromo', e.target.value)} />
						<Form.Text className="text-muted">
							Enter a valid video URL. Students who watch a well-made promo
							video are 5X more likely to enroll in your course.
						</Form.Text>
					</Form.Group>
				</Card.Body>
			</Card>
		</Form>
	);
};
export default CoursesMedia;