// import node module libraries
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.svg';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../../../src/firebase';
import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";


const SignUp = () => {
	
	const [role, setRole] = useState('');
	const [userUID, setUserUID] = useState();
	const emailRef = useRef();
	const passwordRef = useRef();
	// const confirmPasswordRef = useRef();
	const roleRef = useRef();
	const navigate = useNavigate();

	const handleChange = (e) => {
		setRole(e.target.value);
		
	}

	const registerNewUser = async (email, password) => {
		try {
			const userCredential = await createUserWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;
			var timestamp = serverTimestamp();

			const userDocRef = doc(db, "users", user.uid);
			const userData = {
				id: user.uid,
				role: ["student"], // visiems reigistruojantis suteikiama studento role
				account: email,
				createdAt: timestamp
			};

			await setDoc(userDocRef, userData);
				navigate('/marketing/student/dashboard/');
		} catch (error) {
			const errorCode = error.code;
			const errorMessage = error.message;
			// Handle the error as needed
			console.log(errorCode + " " + errorMessage);
		}
	};
	
	function handleSignUp(e) {
		e.preventDefault();
		registerNewUser(emailRef.current.value, passwordRef.current.value);
	}

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign up</h1>
								<span>
									Already have an account?{' '}
									<Link to="/authentication/sign-in" className="ms-1">
										Sign in
									</Link>
								</span>
							</div>
							{/* Form */}
							<Form className='was-validated'>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* User Name */}
										<Form.Label>Email</Form.Label>
										<Form.Control
											ref={emailRef}
											type="text"
											id="username"
											placeholder="User email"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* email */}
										<Form.Label>Password</Form.Label>
										<Form.Control
											ref={passwordRef}
											type="password"
											id="email"
											placeholder="Enter password"
											required
										/>
									</Col>
									{/* <Col lg={12} md={12} className="mb-3">
										<Form.Label>Confirm Password</Form.Label>
										<Form.Control
										ref={confirmPasswordRef}
											type="password"
											id="password"
											placeholder="**************"
										/>
									</Col> */}
									<Col lg={12} md={12} className="mb-3">
									{/* <Form.Label>Student</Form.Label> */}
									{/* <Form.Group ref={roleRef}>
										<Form.Check onChange={handleChange} value='Student' name='type' aria-label='Student' label="Student" type='radio'checked={role === 'Student'} required/>
										<Form.Check onChange={handleChange} value='Instructor' name='type' aria-label='Instructor' label="Instructor" type='radio'checked={role === 'Instructor'} required/>

									</Form.Group> */}
									{/* <div className="form-check">
										<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
										<label className="form-check-label" htmlFor="flexRadioDefault1">
											Student
										</label>
										</div>
										<div className="form-check">
										<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
										<label className="form-check-label" htmlFor="flexRadioDefault2">
											Instructor
										</label>
										</div> */}
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<Form.Check type="checkbox" id="check-api-checkbox" required>
											<Form.Check.Input type="checkbox" required/>
											<Form.Check.Label>
												I agree to the
												<Link to="/pages/terms-and-conditions">
													Terms of Service{' '}
												</Link>{' '}
												and{' '}
												<Link to="/pages/terms-and-conditions">
													Privacy Policy.
												</Link>
											</Form.Check.Label>
										</Form.Check>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<Button variant="primary" onClick={handleSignUp}>
											Register
										</Button>
									</Col>
								</Row>
							</Form>
							<hr className="my-4" />
							<div className="mt-4 text-center">
								{/* Facebook */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-facebook"
								>
									<i className="fab fa-facebook"></i>
								</Link>{' '}
								{/* Twitter */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-twitter"
								>
									<i className="fab fa-twitter"></i>
								</Link>{' '}
								{/* LinkedIn */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-linkedin"
								>
									<i className="fab fa-linkedin"></i>
								</Link>{' '}
								{/* GitHub */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-github"
								>
									<i className="fab fa-github"></i>
								</Link>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignUp;
