// import node module libraries
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';

// import layouts
import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import AllRoutes from 'layouts/AllRoutes';

// import required stylesheet
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';
import PrivateRoute from 'components/dashboard/authentication/PrivateRoute';



function App() {
	// var navigate = useNavigate();
	return (
		// <PrivateRoute>

		<Router>
			 
			{localStorage.getItem('authUser') ? 
			<div>

				<ScrollToTop />
				<AllRoutes />
			</div>
			: 	<PrivateRoute />} 
		</Router>
		// {/* </PrivateRoute> */}
	);
}

export default App;
