// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar2 from 'assets/images/avatar/avatar-2.jpg';
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import Avatar4 from 'assets/images/avatar/avatar-4.jpg';


import { auth } from '../../src/firebase';
import { getAuth, updateProfile } from "firebase/auth";

const user = auth.currentUser;
if (user !== null) {
	user.providerData.forEach((profile) => {
	  console.log("Sign-in provider: " + profile.providerId);
	  console.log("  Provider-specific UID: " + profile.uid);
	  console.log("  Name: " + profile.displayName);
	  console.log("  Email: " + profile.email);
	  console.log("  Photo URL: " + profile.photoURL);
	  console.log("  Photo URL: " + profile.role);
	});
  } else {
	// console.log("neveikia");

}
// updateProfile(auth.currentUser, {
// 	displayName: "Jane Q. User", photoURL: "https://example.com/jane-q-user/profile.jpg"
//   }).then(() => {
// 	// Profile updated!
// 	// ...
//   }).catch((error) => {
// 	// An error occurred
// 	// ...
//   });

const Notification = [
	{
		id: 1,
		read: false,
		image: Avatar1,
		sender: 'Kristin Watson',
		date: '2 hours ago',
		time: '2:19 PM',
		message: `Krisitn Watsan like your comment on course Javascript Introduction!`
	},
	{
		id: 2,
		read: true,
		image: Avatar2,
		sender: 'Brooklyn Simmons',
		date: 'Oct 9,',
		time: '1:20 PM',
		message: `Just launched a new Courses React for Beginner.`
	},
	{
		id: 3,
		read: true,
		image: Avatar3,
		sender: 'Jenny Wilson',
		date: 'Oct 9,',
		time: '1:56 PM',
		message: `Krisitn Watsan like your comment on course Javascript Introduction!`
	},
	{
		id: 4,
		read: true,
		image: Avatar4,
		sender: 'Sina Ray',
		date: 'Oct 9,',
		time: '1:56 PM',
		message: `You earn new certificate for complete the Javascript Beginner course.`
	}
];

export default Notification;
