// import node module libraries
import React from 'react';
import { Fragment } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import CourseCardEducation from '../CourseCardEducation';


const EducationIndex = () => {
    return (
        <Fragment>
            <Container>
                <section className="py-lg-16 py-6">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className=" col-xxl-5  col-xl-6 col-lg-6 col-12">
                            <div>
                                <h1 className="display-2 fw-bold mb-3">Learn today’s most in-<u className="text-warning"><span className="text-primary">demand-skills</span></u></h1>
                                <p className="lead mb-4">Classes &amp; Courses website template to start creating your stunning website. All
                                    templates
                                    are fully customizable.</p>
                                <ul className="list-unstyled mb-5">
                                    <li className="mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="var(--geeks-success)" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                        <span className="ms-2">No credit card required</span>
                                    </li>
                                    <li className="mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="var(--geeks-success)" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                        <span className="ms-2">Customer service 24/7</span>
                                    </li>
                                    <li className="mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="var(--geeks-success)" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                        <span className="ms-2">No setup fee</span>
                                    </li>
                                    <li className="mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="var(--geeks-success)" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                        </svg>
                                        <span className="ms-2">Cancel any time</span>
                                    </li>
                                </ul>
                                <a href="#!" className="btn btn-dark btn-lg">Explore Online Courses</a>
                            </div>
                        </div>
                        <div className="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6 col-12 d-lg-flex justify-content-end">
                            <div className="mt-12 mt-lg-0 position-relative">
                                <div className="position-absolute top-0 start-0 translate-middle  d-none d-md-block">
                                    <img src="../assets/images/svg/graphics-2.svg" alt="graphics-2" />
                                </div>
                                <img src="../assets/images/education/skils.jpg" alt="online course" className="img-fluid rounded-4 w-100 z-1 position-relative" />
                                <div className="position-absolute top-100 start-100 translate-middle  d-none d-md-block">
                                    <img src="../assets/images/svg/graphics-1.svg" alt="graphics-1" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </Container>
            

<CourseCardEducation />
           


            <section className="py-lg-10 py-6 bg-gray-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
                            <div className="row align-items-center">
                                <div className="col-lg-5 col-md-12 col-12">
                                    <div className="mb-5 mb-lg-0">
                                        <h2 className="display-4 fw-bold mb-3">Learn latest Skills; Advance <u className="text-warning"><span className="text-primary">your
                                            career
                                        </span></u></h2>
                                        <p className="mb-5 lead">Donec sit quam lacinia et sed et. Lectus in pellent esque sed a eget mauris.
                                            Tincidunt
                                            tincidunt
                                            sit quisque orci turpis nulla velit.</p>
                                        <a href="#!" className="btn btn-outline-secondary">Get Started</a>
                                    </div>
                                </div>
                                <div className="col-lg-6 offset-lg-1 col-md-12 col-12">


                                    <div className="d-flex justify-content-center position-relative rounded py-17 border-gray-100 border border-4 rounded-3 bg-cover" 
                                    style={{
                                    // backgroundImage: url(/assets/images/education/edu-video-img.jpg)
    }}>
                                        <a className="popup-youtube icon-shape rounded-circle btn-play icon-xl text-decoration-none" href="https://www.youtube.com/watch?v=JRzWRZahOVU">
                                            <i className="fe fe-play"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-6">
                                <div className="container">
                                   <div className="row">
                                      <div className="col-xxl-4 col-xl-6 col-lg-12">
                                         <div className="card mb-4 mb-xl-0 card-hover border">
                                            <a href="#!">
                                            <img src="../assets/images/education/edu-webinar-1.jpg" alt="webinar-1" className="img-fluid w-100 rounded-top-3"/>
                                            </a>
                                            <div className="card-body">
                                               <h3 className="mb-4 text-truncate">
                                                  <a href="#!" className="text-inherit ">Education Edition Deployment And Set Up</a>
                                               </h3>
                                               <div className="mb-4">
                                                  <div className="mb-3 lh-1">
                                                     <span className="me-1">
                                                     <i className="bi bi-calendar-check"></i>
                                                     </span>
                                                     <span>Thu, November 10, 2023</span>
                                                  </div>
                                                  <div className="lh-1">
                                                     <span className="me-1">
                                                     <i className="bi bi-clock"></i>
                                                     </span>
                                                     <span>6:00 PM – 8:00 PM GMT</span>
                                                  </div>
                                               </div>
                                               <a href="#!" className="btn btn-light-primary text-primary">Register Now</a>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </section>

            <section className="py-lg-10">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
                            <div className="bg-primary py-6 px-6 px-xl-0 rounded-4 ">
                                <div className="row align-items-center">
                                    <div className="offset-xl-1 col-xl-5 col-md-6 col-12">
                                        <div>
                                            <h2 className="h1 text-white mb-3">Let’s find the right course for you!</h2>
                                            <p className="text-white fs-4">…and achieve their learning goals. With our expert tutors, your goals are
                                                closer
                                                than ever!</p>
                                            <button className="btn btn-dark">Start learning</button>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-12">
                                        <div className="text-center">
                                            <img src="../assets/images/education/course.png" alt="learning" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- footer --> */}
            <footer className="pt-5 pb-3">
                <div className="container">
                    <div className="row  justify-content-center text-center  align-items-center ">
                        <div className="col-12 col-md-12 col-xxl-6 px-0 ">
                            <div className="mb-4">
                                <a href="../index.html"><img src="../assets/images/brand/logo/logo-2.svg" alt="" className="mb-4" /></a>
                                <p className="lead">Geek is feature rich components and beautifully Bootstrap 5 template for
                                    developers,
                                    built with bootstrap responsive framework.
                                </p>
                            </div>
                            <nav className="nav nav-footer justify-content-center">
                                <a className="nav-link" href="#">About</a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Careers </a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Contact</a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Pricing</a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Blog</a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Affilliate</a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Help</a>
                                <span className="my-2 vr opacity-50"></span>
                                <a className="nav-link" href="#">Investors</a>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Desc --> */}
                    {/* <hr className="mt-6 mb-3"> */}
                    <div className="row align-items-center">
                        {/* <!-- Desc --> */}
                        <div className="col-lg-3 col-md-6 col-12">
                            <span>
                                ©
                                <span id="copyright">
                                    <script>
                                        document.getElementById('copyright').appendChild(document.createTextNode(new Date()
                                        .getFullYear()))
                                    </script>
                                </span>
                                Geeks-UI, Inc. All Rights Reserved
                            </span>
                        </div>
                        {/* <!-- Links --> */}
                        <div className="col-12 col-md-6 col-lg-7 d-lg-flex justify-content-center">
                            <nav className="nav nav-footer">
                                <a className="nav-link ps-0" href="#">Privacy Policy</a>
                                <a className="nav-link px-2 px-md-0" href="#">Cookie Notice </a>
                                <a className="nav-link" href="#">Terms of Use</a>
                            </nav>
                        </div>
                        <div className="col-lg-2 col-md-12 col-12 d-lg-flex justify-content-end">
                            <div className="">
                                {/* <!--Facebook--> */}
                                <a href="#" className="text-muted me-2">
                                    <i className="mdi mdi-facebook fs-3"></i>
                                </a>
                                {/* <!--Twitter--> */}
                                <a href="#" className="text-muted me-2">
                                    <i className="mdi mdi-twitter  fs-3"></i>
                                </a>
                                {/* <!--GitHub--> */}
                                <a href="#" className="text-muted">
                                    <i className="mdi mdi-github fs-3"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Links --> */}
                </div>
            </footer>

        </Fragment>
    );
};

export default EducationIndex;
