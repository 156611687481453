// import node module libraries
import { Fragment, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Alert } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.svg';
import { auth, db } from '../../../../src/firebase';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from "firebase/firestore";
import AllRoutes from 'layouts/AllRoutes';
import { replace } from 'formik';



onAuthStateChanged(auth, (authUser) => {
	if(authUser) {
		localStorage.setItem('authUser', JSON.stringify(authUser));
	}
	  else {
		  localStorage.removeItem('authUser')
	  }
  });


const PrivateRoute = () => {
	const emailRef = useRef();
	const passwordRef = useRef();
	const navigate = useNavigate();



	async function login(email, password) {
		try {
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;
			const docRef = doc(db, "users", user.uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const role = docSnap.data().role;
				// console.log("Document data:", docSnap.data());
				// console.log("Document data:", docSnap.data().role);

					if(role === "Admin") {
						window.location.reload(true);
						// navigate('/', {replace: true});
						// <AllRoutes />
						console.log("prisilogino su admin");
						} else {
							navigate('/dashboard/overview/');
					}

			  } else {
				navigate('/');
				console.log("nepavyko prisiloginti");
			  }
		} catch (error) {
			const errorCode = error.code;
			const errorMessage = error.message;
		}
	}


	function handleLogin() {
		login(emailRef.current.value, passwordRef.current.value);
	}

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={3} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								{/* <Link to="/">
									<Image src={Logo} className="mb-4 d-flex justify-content-center" alt="" />
								</Link> */}
								<h1 className="mb-1 fw-bold d-flex justify-content-center">Sign in</h1>
							</div>
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Username or email </Form.Label>
										<Form.Control
											ref={emailRef}
											type="email"
											id="email"
											placeholder="Email address here"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<Form.Control
											ref={passwordRef}
											type="password"
											id="password"
											placeholder="**************"
											required
										/>
									</Col>
								
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<Button variant="primary" onClick={handleLogin}>
											Sign in
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default PrivateRoute;
