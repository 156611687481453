// import node module libraries
import { Card, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { v4 as uuidv4, v4 } from 'uuid';
// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { addDoc, arrayUnion, collection, doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from '../../../../../../../src/firebase';
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as tus from 'tus-js-client';

import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';


function BasicInformation(props) {

	const [imgProgress, setImgProgress] = useState(0);
	const [vidProgress, setVidProgress] = useState(0);
	const timestamp = serverTimestamp();
	const [uploaded, setUploaded] = useState(false);
	const navigate = useNavigate();


	const [form, setForm] = useState({});
	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		})
	}


	useEffect(() => {
		if (form.cImageURL) {
			tusUpload();
			//   console.log("uploadinama i stream");
		}
	}, [form?.cImageURL]);

	useEffect(() => {
		if (form.cVideoId && uploaded) {
			handleAdd();
			  console.log("uploadinama i firebase", form);
			// setUploaded(false); ši eilutė nebėra reikalinga, kadangi uploaded tikrinama tik vieną kartą
		}
	}, [form.cVideoId, uploaded]);



	// ikelti kurso paveiksleli
	const handleImage = (e) => {
		// e.preventDefault();
		uploadCourseImage(form.cImage);

	}
	// ikelti kurso paveiksleli paduodant reiksme
	const uploadCourseImage = (photoFile) => {
		if (!photoFile) return;
		const filesRef = ref(storage, `gallery/${auth.currentUser.uid}/${v4() + photoFile.name}`);
		const uploadTask = uploadBytesResumable(filesRef, photoFile);

		uploadTask.on("state_changed", (snapshot) => {
			const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			setImgProgress(prog);
			console.log("paveiksliukas " + prog + "%");
		},
			(err) => console.log(err),
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((url) => {
					if (url) {
						// tusUpload();
						// console.log("dar nepriskirtas paveiksliuko url " + url);
						// setForm((prevForm) => ({ ...prevForm, cImageURL: url }));
						setField('cImageURL', url); // ikelto paveikslelio url priskyrimas
						// console.log("paveiksliuko nuoroda " + form.cImageURL);
					} else { console.log("negavo nuorodos"); }
				});
			},

		);
	}



	//========================================tus upload to stream=====================================================
	// const mediaId = '';
	const tusUpload = async () => {
		const prefixName = auth.currentUser.uid + "_" + uuidv4() + "_" + form.cVideo.name;
		const ACCOUNT_ID = "3cb967416930271b3c8fec94a0228525";
		const endpoint = `https://api.cloudflare.com/client/v4/accounts/${ACCOUNT_ID}/stream/`;
		const token = 'O1Qe2QWKwtLo1GIw0messqZKUW2u9oEgJlKajDtS';
		const file = form.cVideo;

		const options = {
			metadata: {
				name: prefixName,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
			chunkSize: 25 * 1024 * 1024, // 25MB chunk size, suskaido faila i dalis po 25MB
		};
		const upload = new tus.Upload(file, {
			endpoint,
			...options,
			onError: (error) => {
				console.error('Upload error:', error);
			},
			onProgress: (bytesUploaded, bytesTotal) => {
				const progress = (bytesUploaded / bytesTotal) * 100;
				console.log(`Upload progress: ${progress.toFixed(2)}%`);
				setVidProgress(progress.toFixed(0));
				setProgress(progress.toFixed(0));

			},
			onSuccess: () => {
				console.log('Upload complete');
				setUploaded(true);

			},
			// cia gaunu ikeliamo video URL, onAfterResponce ivyksta pirmiau nei onSuccess
			onAfterResponse: function (req, res) {
				return new Promise(async resolve => {
					var mediaIdHeader = res.getHeader('stream-media-id'); // cia gaunu ikeliamo video ID, kurio reikia gauti URL
					if (mediaIdHeader) {
						  console.log("videoID " + mediaIdHeader); //this is videoID
						setField('cVideoId', mediaIdHeader);
						// const options = {
						// 	method: 'GET',
						// 	url: endpoint + mediaIdHeader,
						// 	headers: {
						// 		'Content-Type': 'application/json',
						// 		'X-Auth-Email': '',
						// 		'Authorization': 'Bearer O1Qe2QWKwtLo1GIw0messqZKUW2u9oEgJlKajDtS'
						// 	}
						// };

						// await axios.request(options).then(function (response) {
						// 	// setField('cVideoURL', response.data.result.preview); // cia gaunu video URL ir ji priskiriu kintamajam
						// 	setField('cVideoId', response.data.result.uid); // cia video failo ilgis
						// }).catch(function (error) {
						// 	console.error(error);
						// });
					}
					resolve();
				});
			}
		});
		upload.start();
	}

	//========================================tus=====================================================

	// iraso i frebase visus duomenis apie kursa
	const handleAdd = async (e) => {
		// e.preventDefault();
		try {
			const docRef = await addDoc(collection(db, "courses"), {
				date: timestamp,
				students: 0,
				votes: 0,
				id: uuidv4(),
				category: form.cCategory,
				image: form.cImageURL,
				title: form.cTitle,
				description: form.cDesc,
				date_added: timestamp,
				instructor_name: 'Vardas/nickname',
				instructor_image: form.cImageURL,
				status: 'Pending',
				level: 'Beginner',
				duration: '',
				remainingTime: form.cRemTime,
				price: form.cPrice,
				discount: 0,
				rating: 0,
				ratingby: 0,
				recommended: true,
				popular: true,
				trending: true,
				progress: 0,
				uid: auth.currentUser.uid,
				vID: form.cVideoId
			});
			AddRole();
			navigate('/marketing/instructor/instructor-my-courses/');
			console.log("kursas ikeltas");
		} catch (error) {
			console.error(error.code, error.message);
		}
	};


	const AddRole = async (e) => {
		try {
		  const userId = auth.currentUser.uid;
		  const documentRef = doc(db, 'users', userId);
		  const docSnap = await getDoc(documentRef);
	  
		  if (docSnap.exists()) {
			const existingRoles = docSnap.data().role;
	  
			if (!existingRoles.includes('Instructor')) {
			  existingRoles.push('Instructor');
	  
			  await updateDoc(documentRef, {
				role: existingRoles,
			  });
	  
			  console.log('Pridėta "Instructor" reikšmė į role masyvą.');
			} else {
			  console.log('Vartotojas jau turi "Instructor" reikšmę role masyve.');
			}
		  } else {
			console.log('Dokumentas neegzistuoja.');
		  }
		} catch (error) {
		  console.error('Įvyko klaida:', error);
		}
	  }
	  
	//-------------------------------------ikelti fake duomenis===========================

	const handleFake = async (e) => {
		e.preventDefault();
		const img = "https://firebasestorage.googleapis.com/v0/b/mycources-f559d.appspot.com/o/gallery%2FFpK6FFeNCbQ2vFzOLqKjWnULlRA2%2F387cbdf8-b9dd-44a6-bd09-8919d5b6d0d8test.jpg?alt=media&token=f377d7c4-399b-4214-a1a8-280abe07ad9b";
		const uid = "ws6Nn4nKXuYBabTr4gzmRiQF1V62";
		const uid1 = "2iSfRN24GveiRAcW2erB2q8pp0a2";
		try {
			// Add a new document with a generated id.
			const docRef = await addDoc(collection(db, "courses"), {
				date: '7 July, 2021 1:42pm',
				students: 12234,
				votes: 3250,
				id: 2,
				category: 'react',
				image: img,
				title: 'Angular - best of the best',
				date_added: 'Added on 5 July, 2021',
				instructor_name: 'Jenny Wilson',
				instructor_image: img,
				status: 'Pending',
				level: 'Intermediate',
				duration: '3h 30m',
				price: 250,
				discount: 150,
				rating: 4.5,
				ratingby: 2800,
				recommended: true,
				popular: true,
				trending: true,
				progress: 88,
				uid: uid
			});
			// console.log("Document written with ID: ", docRef.id);
		} catch (error) {
			console.error(error.code, error.message);
		}
	};
	

	//-------------------------------------ikelti fake duomenis===========================

	const categoryOptions = [
		{ value: 'React', label: 'React' },
		{ value: 'Javascript', label: 'Javascript' },
		{ value: 'HTML', label: 'HTML' },
		{ value: 'Vuejs', label: 'Vue js' },
		{ value: 'Gulpjs', label: 'Gulp js' }
	];

	const CoursesLevel = [
		{ value: 'Intermediate', label: 'Intermediate' },
		{ value: 'Beignners', label: 'Beignners' },
		{ value: 'Advance', label: 'Advance' }
	];

	const initialValue = `<p>Insert course description</p>
                      <p><br /></p>        
                      <p>Some initial <strong>bold</strong> text</p>
                      <p><br /></p><p><br /></p><p><br /></p><p><br /></p>`;

	const [showModal, setShowModal] = useState(false);
	const [progress, setProgress] = useState(0);
	const [progressInterval, setProgressInterval] = useState(null);

	useEffect(() => {
		if (progress === 100) {
			//   clearInterval(progressInterval);
			setTimeout(() => {
				setShowModal(false);
				setProgress(0);
			}, 500);
		}
	}, [progress]);

	const handleShowModal = () => {
		setShowModal(true);
		handleImage();
		// const interval = setInterval(() => {
		//   setProgress(prevProgress => {
		// 	if (prevProgress < 100) {
		// 	  return prevProgress + 1;
		// 	} else {
		// 	  clearInterval(interval);
		// 	  return prevProgress;
		// 	}
		//   });
		// }, 50);
		// setProgressInterval(interval);
	};

	const handleCloseModal = () => {
		// clearInterval(progressInterval);
		setShowModal(false);
		setProgress(0);
	};


	return (
		<Form>
			{/* Card */}
			<Card className="mb-3 ">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Basic Information</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{/* Title  */}
					<Form.Group className="mb-3" controlId='cTitle'>
						<Form.Label>Course Title</Form.Label>
						<Form.Control
							type="text"
							placeholder="Course Title"
							name="course_title"
							onChange={(e) => setField('cTitle', e.target.value)}
						/>
						<Form.Text className="text-muted">
							Write a 60 character course title.
						</Form.Text>
					</Form.Group>

					{/* Category */}
					<Form.Group className="mb-3" controlId='cCategory'>
						<Form.Label>Category category</Form.Label>
						<FormSelect
							options={categoryOptions}
							name="category_category"
							placeholder="Select Category"
							onChange={(e) => setField('cCategory', e.target.value)}
						/>
						<Form.Text className="text-muted">
							Help people find your courses by choosing categories that
							represent your course.
						</Form.Text>
					</Form.Group>

					{/* Course Description*/}
					<Form.Group className="mb-3" controlId='cDesc'>
						<Form.Label>Course Description</Form.Label>
						{/* <ReactQuillEditor
							initialValue={initialValue}
							name="course_description"
							onChange={(e) => setField('cDesc', e.target.value)}
						/> */}
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							{/* <Form.Label>Example textarea</Form.Label> */}
							<Form.Control as="textarea" rows={5} placeholder="Course description" onChange={(e) => setField('cDesc', e.target.value)} />
						</Form.Group>
						<Form.Text className="text-muted">
							A brief summary of your courses.
						</Form.Text>
					</Form.Group>

					{/* Price  */}
					<Form.Group className="mb-3" controlId='cPrice'>
						<Form.Label>Course Price </Form.Label>
						<Form.Control
							type="number"
							placeholder="Course Price"
							name="course_price"
							onChange={(e) => setField('cPrice', e.target.value)}
						/>
						<Form.Text className="text-muted">
							Course price in euro.
						</Form.Text>
					</Form.Group>

					{/* Kiek laiko galios video perziura nuo pirkimo datos  */}
					<Form.Group className="mb-3" controlId='cRemTime'>
						<Form.Label>Course Time </Form.Label>
						<Form.Control
							type="number"
							placeholder="Course Time"
							name="course_time"
							onChange={(e) => {
								if (!e.target.value || e.target.value !== 0) {
									setField('cRemTime', e.target.value)
								}
							}
							}
						/>
						<Form.Text className="text-muted">
						How many days will the course be available for viewing, 0 or empty is no time limit.
						</Form.Text>
					</Form.Group>

				</Card.Body>
			</Card>

			{/* Card Course Media*/}

			{/* Card */}
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Courses Media</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>

					{/* Course video file */}
					<Form.Label>Course video file</Form.Label>
					<Form.Group className="mb-1 input-group">
						<Form.Control
							id="inputfavicons"
							type="file"
							className="form-control"
							accept="video/*"
							onChange={(e) => {
								// setUploadFile(e.target.files[0]);
								setField('cVideo', e.target.files[0]);
							}}
						/>
						<Form.Text className="text-muted">
							Upload your course video here. It must meet our course image quality standards to be accepted. Important guidelines: max resulution 1920x1080
							pixels; Maximum video file size is 2GB and with no time limit.
						</Form.Text>
					</Form.Group>

					{/* Course cover image */}
					<Form.Label>Course cover image</Form.Label>
					<Form.Group className="mb-1 input-group">
						<Form.Control
							id="inputfavicon"
							type="file"
							className="form-control"
							onChange={(e) => {
								// setUploadImage(e.target.files[0]);
								setField('cImage', e.target.files[0]);
							}} />
						{/* <Form.Label
							htmlFor="inputfavicon"
							className="input-group-text mb-0">Upload</Form.Label> */}
						<Form.Text className="text-muted">
							Upload your course image here. It must meet our course image
							quality standards to be accepted. Important guidelines: 750x440
							pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
						</Form.Text>
					</Form.Group>
					<div>
					</div>

					{/* Video URL  */}
					{/* <Form.Group className="mb-3 mt-3">
						<Form.Label>Course promo video</Form.Label>
						<Form.Control type="text" placeholder="Video URL" id="VideoURL" onChange={(e) => setField('cPromo', e.target.value)} />
						<Form.Text className="text-muted">
							Enter a valid video URL. Students who watch a well-made promo
							video are 5X more likely to enroll in your course.
						</Form.Text>
					</Form.Group> */}
				</Card.Body>
			</Card>


			{/* <CoursesMedia /> */}
			{/* Button */}
			<Button variant="primary" onClick={handleShowModal}>Submit</Button>
			<Button variant="primary" onClick={AddRole}>prideti role</Button>
			{/* <Button variant="primary" onClick={handleFake}>Fake data</Button> */}
			{/* <ProgressBar now={25} label={`${25}%`} visuallyHidden /> */}

			<div className="modal-progress">
				{/* <Button onClick={handleShowModal}>Rodyti popup</Button> */}
				<Modal show={showModal} onExit={handleCloseModal}>
					<div className="modal-bg">

						<Modal.Body>
							<div className="progress-circle-container">
								<div className="progress-circle">
									video uploading
									<ProgressBar
										now={progress}
										label={`${progress}%`}
										className="circle-progress-bar"
									/>
									{/* <div className="circle-value">{progress}</div> */}
								</div>
							</div>
						</Modal.Body>
					</div>
				</Modal>
			</div>
		</Form>

	);
};
export default BasicInformation;
