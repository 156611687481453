// import node module libraries
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Alert } from 'react-bootstrap';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.svg';
import { auth, db } from '../../../../src/firebase';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from "firebase/firestore";




onAuthStateChanged(auth, (authUser) => {
	if(authUser) {
		localStorage.setItem('authUser', JSON.stringify(authUser))
		
	}
	  else {
		  localStorage.removeItem('authUser')
	  }
  });


const SignIn = () => {
	const emailRef = useRef();
	const passwordRef = useRef();
	const navigate = useNavigate();
	const roleRef = useRef();
	const [checkRole, setCheckRole] = useState('');


	async function login(email, password) {
		try {
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;
			const docRef = doc(db, "users", user.uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const role = docSnap.data().role
				console.log("Document data:", docSnap.data());
				console.log("Document data:", docSnap.data().role);

					if(role === "Instructor") { // cia jei role === 
						navigate('/marketing/instructor/dashboard/');
						} else if (role === "Student") {
							navigate('/marketing/student/dashboard/');
							} else {
								
								navigate('/dashboard/overview/');
					}

			  } else {
				// docSnap.data() will be undefined in this case
				console.log("Registruok nauja!");
			  }
		} catch (error) {
			const errorCode = error.code;
			const errorMessage = error.message;
		}
	}


	function handleLogin() {
		login(emailRef.current.value, passwordRef.current.value);
	}

	const handleChange = (e) => {
		setCheckRole(e.target.value);
		
	}

	// onAuthStateChanged(auth, (user) => {
	// 	if (user) {
	// 	  // User is signed in, see docs for a list of available properties
	// 	  // https://firebase.google.com/docs/reference/js/auth.user
	// 	//   setDoc(doc(db, "users", auth.currentUser.uid), {
	// 	// 	role: "student",
	// 	// 	account: user.email
	// 	// })
	// 	  const uid = user.uid;
	// 	  if (uid) navigate('/marketing/instructor/add-new-course/');
	// 	  console.log(user.email);
	// 	  // ...
	// 	} else {
	// 	  // User is signed out
	// 	  // ...
	// 	//   navigate('/');
	// 	  console.log("nepavyko ")
	// 	}
	//   });

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign in</h1>
								<span>
									Don’t have an account?{' '}
									<Link to="/authentication/sign-up" className="ms-1">
										Sign up
									</Link>
								</span>
							</div>
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Username or email </Form.Label>
										<Form.Control
											ref={emailRef}
											type="email"
											id="email"
											placeholder="Email address here"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<Form.Control
											ref={passwordRef}
											type="password"
											id="password"
											placeholder="**************"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<div className="d-md-flex justify-content-between align-items-center">
											<Form.Group
												className="mb-3 mb-md-0"
												controlId="formBasicCheckbox"
											>
												<Form.Check type="checkbox" label="Remember me" />
											</Form.Group>
											<Link to="/authentication/forget-password">
												Forgot your password?
											</Link>
										</div>
									</Col>
									<Col lg={12} md={12} className="mb-3">
									{/* <Form.Label>Student</Form.Label> */}
									{/* <Form.Group ref={roleRef}>
										<Form.Check onChange={handleChange} value='Student' name='type' aria-label='Student' label="Student" type='radio' checked={checkRole === 'Student'} required/>
										<Form.Check onChange={handleChange} value='Instructor' name='type' aria-label='Instructor' label="Instructor" type='radio' checked={checkRole === 'Instructor'} required/>

									</Form.Group> */}
									{/* <div className="form-check">
										<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
										<label className="form-check-label" htmlFor="flexRadioDefault1">
											Student
										</label>
										</div>
										<div className="form-check">
										<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
										<label className="form-check-label" htmlFor="flexRadioDefault2">
											Instructor
										</label>
										</div> */}
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<Button variant="primary" onClick={handleLogin}>
											Sign in
										</Button>
									</Col>
								</Row>
							</Form>
							<hr className="my-4" />
							<div className="mt-4 text-center">
								{/* Facebook */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-facebook"
								>
									<i className="fab fa-facebook"></i>
								</Link>{' '}
								{/* Twitter */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-twitter"
								>
									<i className="fab fa-twitter"></i>
								</Link>{' '}
								{/* LinkedIn */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-linkedin"
								>
									<i className="fab fa-linkedin"></i>
								</Link>{' '}
								{/* GitHub */}
								<Link
									to="#"
									className="btn-social btn-social-outline btn-github"
								>
									<i className="fab fa-github"></i>
								</Link>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignIn;
