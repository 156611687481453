// import node module libraries
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

// import sub components
import CourseCard from '../CourseCard';

// import data files
import { AllCoursesData, fetchAllFirestoreData } from 'data/slider/AllCoursesData';
const CourseGridView = () => {
	// const [Records] = useState(AllCoursesData.slice(0, 500));
 	const [AllRecords, setAllRecords] = useState([]);
	const [read, setRead] = useState(false);

	//------paging start----------
	const [pageNumber, setPageNumber] = useState(0);
	const RecordsPerPage = 9;
	const pagesVisited = pageNumber * RecordsPerPage;
	const pageCount = Math.ceil(AllRecords.length / RecordsPerPage);

	// useEffect(() => {
	// 	if (Records.length === 0) {
	// 	  fetchAllFirestoreData().then((res) => {
	// 		setAllRecords(res);
	// 		setRead(true);
	// 		console.log("Priskirti records");
	// 	  });
	// 	} else {
	// 	  console.log("Pirmas effect else");
	// 	}
	
	// 	if (Records.length > 0 && read) {
	// 	  setRead(false);
	// 	  console.log("Pasikeitus", Records);
	// 	} else {
	// 	  console.log("Antras useEffect else");
	// 	}
	//   }, [Records, read]);

	const AllMemo = useMemo(() => {
		fetchAllFirestoreData().then((res) => {
			setAllRecords(res)
		});
		// console.log("===============================================suveikia useMemo");
	}, []);

	// console.log(AllMemo);
	// const [Records, setAllRecords] = useState([]);

	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const displayRecords = AllRecords.slice(
		pagesVisited,
		pagesVisited + RecordsPerPage
	).map((Records, index) => {
		return (
			<Col lg={4} md={6} sm={12} key={index}>
				<CourseCard item={Records} />
			</Col>
		);
	});
	//---end of paging start----------




	// useEffect(() => {
	// 	if(VisiKursai.length > 0) {

	// 		console.log("visi kursai ", VisiKursai);
	// 		console.log("visi records ", Records);
	// 	}
	// }, [])


	return (
		<Fragment>
			
			<Row>
				{displayRecords.length > 0 ? (
					displayRecords
				) : (
					<Col>No matching records found.</Col>
				)}
			</Row>

			<ReactPaginate
				previousLabel={<ChevronLeft size="14px" />}
				nextLabel={<ChevronRight size="14px" />}
				pageCount={pageCount}
				onPageChange={changePage}
				containerClassName={'justify-content-center mb-0 pagination'}
				previousLinkClassName={'page-link mx-1 rounded'}
				nextLinkClassName={'page-link mx-1 rounded'}
				pageClassName={'page-item'}
				pageLinkClassName={'page-link mx-1 rounded'}
				disabledClassName={'paginationDisabled'}
				activeClassName={'active'}
			/>
		</Fragment>
	);
};
export default CourseGridView;
